var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "组件管理",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-add": function ($event) {
            return _vm.addFn()
          },
        },
      }),
      _c(
        "div",
        { staticClass: "search-form", staticStyle: { display: "flex" } },
        [
          _c("el-input", {
            staticClass: "new-sino-input",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "名称", size: "mini" },
            model: {
              value: _vm.queryList.name,
              callback: function ($$v) {
                _vm.$set(_vm.queryList, "name", $$v)
              },
              expression: "queryList.name",
            },
          }),
          _c(
            "el-button-group",
            { staticStyle: { "margin-left": "10px" } },
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-search",
                },
                on: { click: _vm.onLoad },
              }),
              _c("el-button", {
                attrs: { size: "mini", icon: "reset-refresh icon-refresh" },
                on: { click: _vm.czFn },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("grid-layout", {
        ref: "gridLayout",
        attrs: {
          "data-total": _vm.total,
          page: _vm.page,
          tableOptions: _vm.tableOptions,
          tableData: _vm.tableData,
          tableLoading: _vm.tableLoading,
          gridRowBtn: _vm.gridRowBtn,
        },
        on: {
          "page-size-change": _vm.handleSizeChange,
          "page-current-change": _vm.handleCurrentChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
          "grid-edit": _vm.handleEdit,
          "grid-del": _vm.DelList,
        },
        scopedSlots: _vm._u([
          {
            key: "isSave",
            fn: function ({ row }) {
              return [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      row.isEnable == "0"
                        ? _vm.$t("cip.plat.sys.menu.field.no")
                        : _vm.$t("cip.plat.sys.menu.field.yes")
                    ) +
                    "\n          "
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }